import axios from 'axios'
import { config } from '@/core/config'
import { IWebTrackCreate, IWebTrackRoute } from './type'
import { uuid } from 'vue-uuid'

export class WebTrackService {
	private axios
	constructor() {
		const axiosConfig: Record<string, unknown> = {
			baseURL: config.main.SERVICE_ADMIN,
			withCredentials: false,
			headers: {
				'Content-Type': 'application/json',
				Marketplace: config.main.MARKETPLACE,
				'accept-language': config.main.ACCEPT_LANGUAGE
			}
		}
		this.axios = axios.create(axiosConfig)
	}
	async create(webTrackRoute: IWebTrackRoute) {
		try {
			const data: IWebTrackCreate = {
				functionName: webTrackRoute.functionName,
				moduleName: webTrackRoute.moduleName,
				serviceName: 'bp-online',
				traceId: webTrackRoute.needTraceId ? uuid.v4() : ''
			}
			const options = {
				method: 'post',
				url: '/loggers/api/WebTrack',
				data: data,
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`
				} as Record<string, any>
			}
			if (data.traceId) options.headers['traceId'] = data.traceId
			console.log('%cREQUEST DATA', 'color: #1976D2;font-weight: 600;', '\r\n', options)
			const response = await this.axios(options)
			console.log('%cRESPONSE DATA', 'color: #21BA45;font-weight: 600;', '\r\n', response.data)
		} catch (e: any) {
			const response = e?.response?.data
			if (response) console.log('%cRESPONSE DATA', 'color: #C10015;font-weight: 600;', '\r\n', response)
			else console.log('%cRESPONSE DATA', 'color: #C10015;font-weight: 600;', '\r\n', e)
		}
	}
}
